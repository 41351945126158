import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import * as Constants from "../utils/constants"

import { useWindowSize } from "@uidotdev/usehooks";

const SloganRow = () => {
  const size = useWindowSize();
  // @ts-ignore
  if (size.width < 878) {
    return (
      <Row style={{ padding: "3rem", textAlign: "center", fontSize: "20px" }}>
        <Col xs={12} style={{ fontFamily: "Exo2Regular", display: "flex", alignItems: "center", justifyContent: "left", paddingBottom: "0.5rem" }}>
          <Image fluid src={Constants.blueWinvestLogo} alt={Constants.blueWinvestLogo} style={{ paddingRight: "0.5rem" }} />
        </Col>
        <Col xs={12} style={{ fontFamily: "Exo2Regular", display: "flex", alignItems: "center", justifyContent: "left", textAlign: "left" }}>
          <div>
            es posible gracias al respaldo y la trayectoria de Longo Elia Bursátil SA
          </div>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row style={{ padding: "3rem", textAlign: "center", fontSize: "20px" }}>
        <Col style={{ fontFamily: "Exo2Regular", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Image fluid src={Constants.blueWinvestLogo} alt={Constants.blueWinvestLogo} style={{ paddingRight: "0.5rem" }} />
          <span>
            es posible gracias al respaldo y la trayectoria de Longo Elia Bursátil SA
          </span>
        </Col>
      </Row>
    )
  }
}

export default SloganRow;