import React, { useState } from "react";
import axios from "axios";
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import * as Constants from "../utils/constants";

const ContactForm = () => {

  const sendContactFormPayload = async (
    config: {
      subject: string,
      name: string,
      surname: string,
      email: string,
      body: string,
      phone: number
    }) => {
    const res = await axios.post(Constants.contactApi, {
      "recipient": config.email,
      "msgBody": config.body,
      "subject": config.subject,
      "name": config.name,
      "surname": config.surname,
      "phone": config.phone
    });
    return res;
  }

  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    body: "",
    name: "",
    surname: "",
    phone: 0,
    email: "",
    subject: ""
  });
  const [formRes, setFormRes] = useState("");
  const [contactFormSent, setContactFormSent] = useState(false);
  const [buttonSpinnerActive, setButtonSpinnerActive] = useState(false);

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // event.persist();
    } else {
      try {
        event.preventDefault();
        setContactFormSent(true);
        setButtonSpinnerActive(true);
        const response = await sendContactFormPayload({
          body: values.body,
          email: values.email,
          name: values.email,
          surname: values.surname,
          phone: values.phone,
          subject: `Contacto de ${values.surname}, ${values.name}`,
        });
        setFormRes(response.data);
        setButtonSpinnerActive(false);
      } catch (err) {
        setFormRes("Ha ocurrido un error, por favor reintente más tarde.");
        setContactFormSent(true);
        setButtonSpinnerActive(false);
      }
    }
    setValidated(true);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="custom-products-row"
      style={{ backgroundColor: "#EDEDED", padding: "4rem 9rem", textAlign: "left" }}
    >
      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              name="name"
              onChange={(event) => handleChange(event as any)}
              type="text"
              placeholder="Ingresa tu nombre"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSurname">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              name="surname"
              onChange={(event) => handleChange(event as any)}
              type="text"
              placeholder="Ingresa tu apellido"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              onChange={(event) => handleChange(event as any)}
              type="email"
              placeholder="Ingresa tu email"
              required
            />
            <Form.Control.Feedback type="invalid">Por favor, indica un email válido</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              name="phone"
              onChange={(event) => handleChange(event as any)}
              type="tel"
              placeholder="Ingresa tu teléfono"
              pattern="\+?\d+"
              required
            />
            <Form.Control.Feedback type="invalid">Por favor, indica un teléfono válido</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3" controlId="form.ControlMessage">
            <Form.Label>Mensaje</Form.Label>
            <Form.Control
              name="body"
              onChange={(event) => handleChange(event as any)}
              as="textarea"
              rows={12}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col style={{ minHeight: "2rem" }} className="text-center">
          {formRes}
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col style={{ marginTop: "3rem" }} className="text-center">
          <Button
            variant="primary"
            type="submit"
            disabled={contactFormSent}
            style={{ width: "82px" }}
          >
            {buttonSpinnerActive ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
              :
              "ENVIAR"
            }
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default ContactForm;