import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import * as Constants from "../utils/constants";
import breakpointsHelper from "../utils/breakpointsHelper";
import { useWindowSize } from "@uidotdev/usehooks";

const ReportsCarousel = (props: { mainCarouselData: any }) => {
  const size = useWindowSize();
  const SetOfCarouselColumns = (props: { data: any, index: number, numCols: number }) => {
    const cols = [];
    for (let i = 0; i < props.numCols; i++) {

      props.data[i] && cols.push(
        <Col key={i} xs={12} sm={12} md={6} lg={4} xl={4}>
          <a style={{ textDecoration: "inherit" }}
            href={`http://winvest.ar/reports/${props.data[i].filename.split('/')[props.data[i].filename.split('/').length-1]}`} target="_blank" rel="noreferrer">
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: "#00DB94"
            }}>
              <Image fluid src={props.data[i].img} alt={props.data[i].img} style={{
                flexShrink: 0,
                minWidth: "100%",
                minHeight: "100%"
              }} />
            </div>
            <div style={{
              textAlign: "left", padding: "3rem 2rem 2rem 2rem", color: "white",
              backgroundColor: "#323B98", fontFamily: "Exo2Regular", fontSize: "16px"
            }}>
              <div style={{ fontFamily: "utopiaSemibold", fontSize: "26px", minHeight: "5rem" }}>
                {props.data[i].title}
              </div>
              <div style={{ fontFamily: "exo2Regular", fontSize: "16px", minHeight: "10rem" }}>
                <LinesEllipsis
                  text={props.data[i].description}
                  maxLine='2'
                  ellipsis='...'
                  trimRight
                  basedOn='letters'
                />
              </div>
              <div style={{ fontFamily: "exo2Regular", fontSize: "16px", color: "#FFF", paddingTop: "2rem" }}>
                <Image fluid src={Constants.clockIconWhite} alt={Constants.clockIconWhite} style={{
                  flexShrink: 0,
                  height: "1rem",
                  paddingRight: "0.5rem"
                }} />
                {props.data[i].lastUpdate}
              </div>
            </div>
          </a>
        </Col>
      )
    }
    return (<>{cols}</>)
  };
  // @ts-ignore
  const numCols: number = breakpointsHelper(size.width, [1, 1, 2, 3, 3]);

  return (
    <div className="custom-reports-carousel-container" style={{ padding: "2rem 6rem", backgroundColor: "#EDEDED" }}>
      <BootstrapCarousel interval={4000}>
        {props.mainCarouselData.map((reportData: any, index: number) => (
          props.mainCarouselData[index * numCols] &&
          <BootstrapCarousel.Item key={index} style={{ backgroundColor: "#EDEDED", padding: "1rem" }}>
            <Row>
              <SetOfCarouselColumns
                data={props.mainCarouselData.slice(index * numCols, 2 * numCols + index)}
                index={index}
                numCols={numCols}
              />
            </Row>
          </BootstrapCarousel.Item>
        ))}
      </BootstrapCarousel>
    </div>
  )
}

export default ReportsCarousel;