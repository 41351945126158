import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import * as Constants from "../utils/constants";
import ContactForm from "./ContactForm";
import SloganRow from "./SloganRow";
import { useWindowSize } from "@uidotdev/usehooks";

const Contact = () => {
  const size = useWindowSize();

  return (
    <>
      <Row>
        <Col xs={12} md={6} style={{ padding: "5rem" }}>
          <Image fluid src={Constants.contactImg1} width="100%" alt={Constants.contactImg1} />
        </Col>
        <Col xs={12} md={6} style={{ padding: 0 }}>
          <Image fluid src={Constants.contactImg2} width="100%" alt={Constants.contactImg2} />
        </Col>
      </Row>
      <ContactForm />
      <Row style={{ color: "#3B3B3A", backgroundColor: "#FFF" }}>
        <Col className="custom-contact-col" xs={12} lg={6} style={{ padding: "5rem 3rem 3rem 8rem", textAlign: "left" }}>
          <div style={{ fontFamily: "Exo2Bold", fontSize: "32px", }}>
            Nuestras oficinas
          </div>
          <br />
          <br />
          <br />
          <br />
          <Row
            style={{
              padding: ".5rem 0",
              fontFamily: "Exo2Semibold",
              display: "flex"
            }}
          >
            <Col xs={1} style={{ marginRight: "0.5rem", paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Image src={Constants.pinIcon} height="32px" alt={Constants.pinIcon} />
            </Col>
            <Col style={{ fontFamily: "Exo2Semibold", fontSize: "18px" }}>
              <a style={{
                color: "#3B3B3A",
                textDecoration: "inherit",
                paddingLeft: 0,
                fontFamily: "Exo2Semibold"
              }}
                href="https://goo.gl/maps/7GbgpVQnK9F4H3dy6" target="_blank" rel="noopener noreferrer">
                Arias 1639 - Piso 10 Dpto A<br />(C1429DWA) Núñez, CABA, Argentina.
              </a>
            </Col>
          </Row>
          <Row
            style={{
              color: "#3B3B3A",
              padding: ".5rem 0",
              fontFamily: "Exo2Semibold",
              display: "flex"
            }}
          >
            <Col xs={1} style={{ marginRight: "0.5rem", paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Image src={Constants.phoneIcon} height="32px" alt={Constants.phoneIcon} />
            </Col>
            <Col style={{ fontFamily: "Exo2Semibold", fontSize: "18px" }}>
              <a style={{
                color: "#3B3B3A",
                textDecoration: "inherit",
                paddingLeft: 0,
                fontFamily: "Exo2Semibold"
              }}
                href="tel:+541143280020">+54 (11) 3987 9920</a>
            </Col>
          </Row>
          <Row
            style={{
              color: "#3B3B3A",
              padding: ".5rem 0",
              fontFamily: "Exo2Semibold",
              display: "flex"
            }}
          >
            <Col xs={1} style={{ marginRight: "0.5rem", paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Image src={Constants.envelopeIcon} height="32px" alt={Constants.envelopeIcon} />
            </Col>
            <Col style={{ fontFamily: "Exo2Semibold", fontSize: "18px" }}>
              <a
                href={`mailto: ${Constants.contactEmail}`}
                // Styles to remove standard blue color and text underline from anchor tag
                style={{
                  color: "#3B3B3A",
                  textDecoration: "inherit",
                  paddingLeft: 0,
                  fontFamily: "Exo2Semibold"
                }}
              >
                {Constants.contactEmail}
              </a>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={6}>
          <div className="custom-map-container" style={{ padding: "5rem", display: "block", overflow: "hidden", width: "100%" }}>
            <iframe
              title="location-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3286.601855184299!2d-58.4656769!3d-34.5383139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb6a0e18f3235%3A0x121c28c3d2c097ec!2sArias%201639%20Piso%2010%20Dpto%20A%2C%20C1429%20CABA!5e0!3m2!1ses!2sar!4v1680491790331!5m2!1ses!2sar"
              // @ts-ignore
              width={size.width < 664 ?
                // @ts-ignore
                size.width - 30
                :
                // @ts-ignore
                size.width > 1000 && size.width < 1400 ?
                  410
                  :
                  600
              }
              height="600"
              loading="lazy"></iframe>
          </div>
        </Col>
      </Row>
      <SloganRow />
    </>
  )
}

export default Contact;