import React from "react";
import MediaQuery from "react-responsive";
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import * as Constants from "../utils/constants";
import VideoLoop from "./VideoLoop";
import HomeRows from "./HomeRows";
import { useWindowSize } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";

const Home = () => {
  const size = useWindowSize();
  // @ts-ignore
  const maxOverlaySize = size.width > 768 ? "520px" : "85vw";
  const CarouselWithVideo = () => {
    return (
      <Row>
        <BootstrapCarousel interval={4000}>
          <BootstrapCarousel.Item>
            <VideoLoop />
          </BootstrapCarousel.Item>
          {/* @ts-ignore */}
          {/* {size.width > 768 ?
            Constants.mainCarouselImages.map((carouselImage, i) => (
              <BootstrapCarousel.Item key={i}>
                <Image height={800} src={carouselImage} alt={carouselImage} />
              </BootstrapCarousel.Item>
            ))
            : */}
          {Constants.mainCarouselImagesMobile.map((carouselImage, i) => (
            <BootstrapCarousel.Item key={i}>
              <Row style={{ height: 800 }}>
                <Row className="d-flex align-items-center justify-content-center" style={{ paddingRight: 0 }}>
                  <Image
                    src={Constants.mainCarouselBackgroundImages[i]}
                    alt={Constants.mainCarouselBackgroundImages[i]}
                    style={{
                      height: 800,
                      objectFit: "cover",
                      paddingRight: 0
                    }}
                  />
                  <Link
                    to={Constants.mainCarouselImagesLinks[i]}
                  >
                    <Image
                      className="custom-carousel-overlay-image"
                      src={carouselImage}
                      alt={carouselImage}
                      style={{
                        maxWidth: maxOverlaySize,
                        maxHeight: maxOverlaySize,
                        position: "absolute",
                        left: "200px",
                        top: "140px"
                      }}
                    />
                  </Link>
                </Row>
              </Row>
            </BootstrapCarousel.Item>
          ))
          }
        </BootstrapCarousel>
      </Row>
    )
  }
  return (
    <div>
      <MediaQuery maxWidth={700}>
        <CarouselWithVideo />
        <HomeRows />
      </MediaQuery>
      <MediaQuery minWidth={701}>
        <CarouselWithVideo />
        <HomeRows />
      </MediaQuery>
      {/* <div className="parallax"></div> */}
    </div>
  );
}

export default Home;
