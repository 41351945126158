import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import HeaderLogo from "./HeaderLogo";
import * as Constants from "../utils/constants";
import { NavLink, Link } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import breakpointsHelper from "../utils/breakpointsHelper";

const sections = Constants.sections;

export const Header = () => {
  const size = useWindowSize();
  // Hook that alerts clicks outside of the passed ref:
  // const useOutsideAlerter = (ref: any) => {
  //   useEffect(() => {
  //     // Event if clicked on outside of element:
  //     const handleClickOutside = (event: any) => {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         closeNav();
  //       }
  //     }
  //     // Bind the event listener:
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       // Unbind the event listener on clean up:
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [ref]);
  // }

  const toggleNav = () => {
    const currentState = document.getElementById("navbarOverlay")!.style.height;
    currentState === "0%" ?
      // @ts-ignore
      document.getElementById("navbarOverlay")!.style.height = size.height < 878 ? "80%" : "60%"
      :
      document.getElementById("navbarOverlay")!.style.height = "0%";
  };

  const closeNav = () => {
    document.getElementById("navbarOverlay")!.style.height = "0%";
  };

  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);

  const header: any = (
    <div className="row" style={{ justifyContent: "space-between", width: "100%" }}>
      <div
        // @ts-ignore
        className={`col ${size.width > 769 ? "" : ""
          }`}
      >
        <ul className="navbar-nav" style={{ float: "left", marginLeft: "2rem" }}>
          <HeaderLogo />
          <li id="">
            <NavLink
              // className="nav-link"
              exact
              to={""}
              style={{ color: "white" }}
            >
            </NavLink>
          </li>
        </ul>
      </div >
      <div
        // @ts-ignore
        className={`col ${size.width > 769 ? "" : ""
          }`}
      >
        {/* <Button
          size="sm"
          id="header-button-alt"
          className="abri-tu-cuenta-button"
          style={{ fontFamily: "Exo2Medium", float: "right", marginRight: "2rem" }}
          onClick={() => window.location.href = "https://operar.winvest.ar/"}
        >
          ABRÍ TU CUENTA
        </Button> */}
        <Link
          className="nav-link abri-tu-cuenta-button"
          to="/apertura_de_cuentas"
          style={{
            color: "white",
            padding: 0,
            fontFamily: "Exo2Regular"
          }}
        >
          <div>
            <div
              id="header-button-alt"
              className="btn btn-primary btn-sm"
              style={{
                fontFamily: "Exo2Medium", textAlign: "center", fontSize: "0.875rem", padding: "4px 8px", width: "auto", borderRadius: "3px", float: "right", marginRight: "2rem"
              }}
            >
              ABRÍ TU CUENTA
            </div>
          </div>
        </Link>
        <Button
          size="sm"
          id="header-button"
          style={{ fontFamily: "Exo2Medium", float: "right", marginRight: "2rem" }}
          onClick={() => window.open("https://operar.winvest.ar/", "_blank")}
        >
          INGRESAR
        </Button>
      </div>
    </div>
  );

  return (
    <nav
      className="navbar fixed-top navbar-dark navbar-expand justify-content-md-left justify-content-start"
      style={{
        color: Constants.secondaryColor,
        backgroundColor: Constants.primaryColor,
        opacity: Constants.opacity,
        position:
          "relative" /* Repositioning from the natural layout to overlap with transparent header*/,
        width: "100%"
      }}
    >
      <div id="navbarOverlay" className="navbar-overlay" style={{
        fontFamily: "Exo2Regular",
        backgroundColor: "#3B3B3A",
        height: "0%",
        // @ts-ignore
        width: breakpointsHelper(size.width, [
          "70%",
          "45%",
          "40%",
          "30%",
          "20%"
        ])
      }}>
        <button
          className="closebtn btn bg-transparent nav-link"
          style={{ color: "white", zIndex: 999, padding: 0, margin: 0 }}
          onClick={() => closeNav()}
        >
          x
        </button>
        <div className="navbar-overlay-content" >
          {sections.filter((item) => (item.showInNavbar)).map((item, key) => (
            <div className="d-flex justify-content-start navbar-custom-element"
              key={key}
            >
              <li className={`nav-item`} key={key}>
                <NavLink
                  onClick={() => closeNav()}
                  className="nav-link"
                  exact
                  to={`/${item.path}`}
                  style={{ color: "white", paddingLeft: 0, paddingRight: 0 }}
                >
                  <b>{item.visibleName}</b>
                </NavLink>
                <ul className="list-unstyled">
                  {item.children?.map((child, key) => (
                    <li className={`nav-item`}
                      key={key}
                    >
                      <NavLink
                        onClick={() => closeNav()}
                        className="nav-link"
                        exact
                        to={`/${item.path}/${child.path}`}
                        style={{ color: "white", paddingLeft: 0, paddingRight: 0 }}
                      >
                        {child.visibleName}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            </div>
          ))}
        </div>
      </div>
      <button
        className="navbar-toggler d-block"
        type="button"
        data-toggle="collapse"
        // data-target="#navbarSupportedContent" // Removed to suppress its original functionality
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={toggleNav}
        ref={wrapperRef}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {header}
      <div
      >
      </div>
    </nav>
  );
};