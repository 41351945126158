import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as Constants from "../utils/constants";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";

const Products = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Productos" bgColor="#EDEDED" bgImage={Constants.servicesBg} opacityLayerColor="transparent" />
      <Row className="justify-content-md-center custom-products-row" style={{ backgroundColor: "#F2F3F8", padding: "3rem 6rem 0 6rem" }}>
        {Constants.productsData.map((product: any, i: number) => (
          <Col className="custom-products-col" key={i} xs={12} sm={3} style={{ fontFamily: "exo2Regular", color: "#3B3B3A", backgroundColor: "#FFF", margin: "1rem", padding: 0, border: "none", boxShadow: "2px 3px 2px #00000029", minHeight: "29rem" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "28px", height: "6rem", lineHeight: "2rem", verticalAlign: "middle", textAlign: "left", padding: "1.5rem 2rem 0.5rem 2rem", color: "#323B98", backgroundColor: "#E6E6E6" }}>
              {product.title}
            </div>
            <div style={{ minHeight: "10rem", fontFamily: "Exo2Regular", fontSize: "18px", padding: "2rem", textAlign: "left", lineHeight: "1.4rem" }}>
              {product.paragraph}
            </div>
          </Col>
        ))}
      </Row>
      <SloganRow />
    </>
  )
}

export default Products;