import React from "react";
import { Button } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as Constants from "../utils/constants";
import { Link } from "react-router-dom";
import breakpointsHelper from "../utils/breakpointsHelper";
import { useWindowSize } from "@uidotdev/usehooks";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const size = useWindowSize();
  return (
    <div>
      <div
        className="container-fluid custom-footer-container"
        style={{
          paddingTop: "3em",
          paddingBottom: "3em",
          paddingLeft: "5em",
          paddingRight: "5em",
          backgroundColor: Constants.primaryColor,
          color: Constants.secondaryColor,
          // letterSpacing: "5px",
          textAlign: "left"
        }}
      >
        <div
          className="row"
          style={{
            // @ts-ignore
            marginLeft: breakpointsHelper(size.width, [
              "0",
              "0",
              "1rem",
              "1rem",
              "1rem",
            ]),
          }}
        >
          <div className="col-sm">
            <div
              className="row"
              style={{
                lineHeight: "54px",
                fontFamily: "Exo2SemiBold",
                fontSize: "1.3rem",
                fontWeight: 900
              }}
            >
              WINVEST
            </div>
            <Col>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/sobre_winvest"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  SOBRE WINVEST
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/alianzas"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  ALIANZAS
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/servicios"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  SERVICIOS
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/servicios/asesoramiento"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  Asesoramiento
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/servicios/productos"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  Productos
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/servicios/portafolios_sugeridos"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  Portafolios Sugeridos
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/servicios/empresas"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  Empresas
                </Link>
              </div>
            </Col>
          </div>
          <div className="col-sm">
            <div
              className="row d-none d-sm-block"
              style={{
                lineHeight: "54px",
                fontFamily: "Exo2SemiBold",
                fontSize: "1.3rem",
                fontWeight: 900,
                height: "54px"
              }}
            >
            </div>
            <Col>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/apertura_de_cuentas"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  APERTURA DE CUENTAS
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/informes"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  INFORMES
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/perfil_del_inversor"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  PERFIL INVERSOR
                </Link>
              </div>
              {/* <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/cotizaciones"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  Cotizaciones
                </Link>
              </div> */}
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/contactanos"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  CONTACTO
                </Link>
              </div>
              <div
                className="row"
              >
                <Link
                  className="nav-link"
                  to="/links_utiles"
                  style={{
                    color: "white",
                    paddingLeft: 0,
                    paddingBottom: "0.4rem",
                    fontFamily: "Exo2Light"
                  }}
                >
                  LINKS ÚTILES
                </Link>
              </div>
            </Col>
          </div>
          <div className="col-sm">
            <div
              className="row"
              style={{
                lineHeight: "54px",
                fontFamily: "Exo2SemiBold",
                fontSize: "1.3rem",
                fontWeight: 900,
              }}
            >
              LEGALES
            </div>
            <div
              className="row"
            >
              <Link
                className="nav-link"
                to="/terminos_y_condiciones"
                style={{
                  color: "white",
                  paddingLeft: 0,
                  paddingBottom: "0.4rem",
                  fontFamily: "Exo2Light"
                }}
              >
                Términos y Condiciones
              </Link>
            </div>
            <div
              className="row"
            >
              <Link
                className="nav-link"
                to="/politica_de_privacidad"
                style={{
                  color: "white",
                  paddingLeft: 0,
                  paddingBottom: "0.4rem",
                  fontFamily: "Exo2Light"
                }}
              >
                Política de Privacidad
              </Link>
            </div>
            <div
              className="row"
            >
              <a
                className="nav-link"
                style={{
                  color: "white",
                  paddingLeft: 0,
                  paddingBottom: "0.4rem",
                  fontFamily: "Exo2Light"
                }}
                href={Constants.pdfPersonalIdoneo}
                target="_blank" rel="noreferrer"
              >
                Personal Idóneo
              </a>
            </div>
            <div
              className="row"
            >
              <a
                className="nav-link"
                style={{
                  color: "white",
                  paddingLeft: 0,
                  paddingBottom: "0.4rem",
                  fontFamily: "Exo2Light"
                }}
                href={Constants.pdfCodigoDeConducta}
                target="_blank" rel="noreferrer"
              >
                Código de Conducta y Protección al Inversor
              </a>
            </div>
            <div
              className="row"
              style={{
                color: "white",
                paddingLeft: 0,
                padding: ".5rem 0",
                fontFamily: "Exo2Regular"
              }}
            >
              {`© Copyright ${currentYear}`}
            </div>
          </div>
          <div className="col-sm">
            <div
              className="row"
              style={{
                lineHeight: "54px",
                fontFamily: "Exo2SemiBold",
                fontSize: "1.3rem",
                fontWeight: 900,
              }}
            >
              CONTACTO
            </div>
            <Row
              style={{
                color: "white",
                padding: ".5rem 0",
                fontFamily: "Exo2Regular",
                display: "flex"
              }}
            >
              <Col xs={1} style={{ marginRight: "0.5rem", paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Image src={Constants.pinIconFooter} height="32px" alt={Constants.pinIconFooter} />
              </Col>
              <Col style={{ fontFamily: "Exo2Regular" }}>
                <a style={{
                  color: Constants.secondaryColor,
                  textDecoration: "inherit",
                  paddingLeft: 0,
                  fontFamily: "Exo2Regular"
                }}
                  href="https://goo.gl/maps/7GbgpVQnK9F4H3dy6" target="_blank" rel="noopener noreferrer">
                  Arias 1639 - Piso 10 Dpto A<br />(C1429DWA) Núñez, CABA, Argentina.
                </a>
              </Col>
            </Row>
            <Row
              style={{
                color: "white",
                padding: ".5rem 0",
                fontFamily: "Exo2Regular",
                display: "flex"
              }}
            >
              <Col xs={1} style={{ marginRight: "0.5rem", paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Image src={Constants.phoneIconFooter} height="32px" alt={Constants.phoneIconFooter} />
              </Col>
              <Col style={{ fontFamily: "Exo2Regular" }}>
                <a style={{
                  color: Constants.secondaryColor,
                  textDecoration: "inherit",
                  paddingLeft: 0,
                  fontFamily: "Exo2Regular"
                }}
                  href="tel:+541139879920">+54 (11) 3987 9920</a>
              </Col>
            </Row>
            <Row
              style={{
                color: "white",
                padding: ".5rem 0",
                fontFamily: "Exo2Regular",
                display: "flex"
              }}
            >
              <Col xs={1} style={{ marginRight: "0.5rem", paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Image src={Constants.envelopeIconFooter} height="32px" alt={Constants.envelopeIconFooter} />
              </Col>
              <Col style={{ fontFamily: "Exo2Regular" }}>
                <a
                  href={`mailto: ${Constants.contactEmail}`}
                  // Styles to remove standard blue color and text underline from anchor tag
                  style={{
                    color: Constants.secondaryColor,
                    textDecoration: "inherit",
                    paddingLeft: 0,
                    fontFamily: "Exo2Regular"
                  }}
                >
                  {Constants.contactEmail}
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="row"
          style={{
            // @ts-ignore
            marginLeft: breakpointsHelper(size.width, [
              "0",
              "0",
              "1rem",
              "1rem",
              "1rem",
            ]),
            padding: 0,
            marginTop: "2.5rem"
          }}
        >
          <Col xs={12} sm={6} md={3} lg={2} xl={1}
            // @ts-ignore
            style={{ marginBottom: size.width < 768 ? "2rem" : "0" }}
          >
            <a href={Constants.dataFiscalURL} target="_F960AFIPInfo"><Image src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" height="100px" /></a>
          </Col>
          <Col xs={12} md={2} className="align-self-end">
            <div
              className="row"
              style={{
                lineHeight: "54px",
                fontSize: "1.3rem",
                fontWeight: "bold"
              }}
            >
              <Button
                size="sm"
                id="footer-button"
                style={{ fontFamily: "Exo2SemiBold", float: "right", marginRight: "2rem" }}
                onClick={() => window.open(Constants.pdfAranceles)}
              >
                ARANCELES
              </Button>
            </div>
            <div
              className="row"
              style={{
                color: "white",
                padding: ".5rem 0",
                fontFamily: "Exo2Light"
              }}
            >
              ALyC Propio - Matrícula Nº 150 de la CNV Participante Nº 220 de BYMA ACyDI Nº 10
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
}

export default Footer;
