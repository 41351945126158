import React from "react";
import * as Constants from "../utils/constants";
import ChartsRow from "./ChartsRow";
import DataTable from "./DataTable";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";
import { useWindowSize } from "@uidotdev/usehooks";

const SuggestedPortfolios = () => {
  const size = useWindowSize();
  return (
    <>
      <HeaderWithOptionalImage title="Portafolio" bgColor="#EDEDED" bgImage={Constants.portfolioSugerido} opacityLayerColor="transparent" />
      {/* <ChartsRow title="Portafolio sugerido de inversión local" chartsData={[
        {
          title: "conservador",
          reportLink: "https://winvest.ar/downloads/Portfolio%20Conservador.pdf",
          data: [
            { title: 'Liquidez', value: 10, color: '#00C5FA' },
            { title: 'Renta Variable', value: 20, color: '#00DB94' },
            { title: 'Renta fija', value: 70, color: Constants.primaryColor },
          ]
        },
        {
          title: "moderado",
          reportLink: "https://winvest.ar/downloads/Portfolio%20Moderado.pdf",
          data: [
            { title: 'Liquidez', value: 5, color: '#00C5FA' },
            { title: 'Renta Variable', value: 35, color: '#00DB94' },
            { title: 'Renta fija', value: 60, color: Constants.primaryColor },
          ]
        },
        {
          title: "agresivo",
          reportLink: "https://winvest.ar/downloads/Portfolio%20Crecimiento.pdf",
          data: [
            { title: 'Renta Variable', value: 30, color: '#00DB94' },
            { title: 'Renta fija', value: 70, color: Constants.primaryColor },
          ]
        }
      ]} /> */}
      {/* <DataTable
        title="Estadísticas clave - Últimos 3 años"
        // @ts-ignore
        dataHeaders={size.width > 768 ? ["", "CONSERVADOR", "MODERADO", "AGRESIVO"] : ["", "CONSERV.", "MODER.", "AGRES."]}
        dataRows={[
          ["Retorno promedio anual", "5,75%", "7,67%", "10,02%"],
          ["Riesgo", "6,14%", "8,02%", "11,37%"],
          ["Mayor caída", "7,73%", "9,37%", "12,22%"]
        ]}
      /> */}
      <ChartsRow title="Portafolio sugerido de inversión global" backgroundColor="#E6E6E6" chartsData={[
        {
          title: "conservador",
          reportLink: "http://winvest.ar/files/PortfolioConservador.pdf",
          data: [
            { title: 'Al y Otros', value: 4, color: '#00C5FA' },
            { title: 'Renta Variable', value: 29, color: '#00DB94' },
            { title: 'Renta fija', value: 67, color: Constants.primaryColor },
          ]
        },
        {
          title: "moderado",
          reportLink: "http://winvest.ar/files/PortfolioModerado.pdf",
          data: [
            { title: 'Al y Otros', value: 4, color: '#00C5FA' },
            { title: 'Renta Variable', value: 51, color: '#00DB94' },
            { title: 'Renta fija', value: 45, color: Constants.primaryColor },
          ]
        },
        {
          title: "agresivo",
          reportLink: "http://winvest.ar/files/PortfolioAgresivo.pdf",
          data: [
            { title: 'Al y Otros', value: 5, color: '#00C5FA' },
            { title: 'Renta Variable', value: 68, color: '#00DB94' },
            { title: 'Renta fija', value: 27, color: Constants.primaryColor },
          ]
        }
      ]}
      />
      <DataTable
        title="Estadísticas clave - Últimos 3 años"
        backgroundColor="#E6E6E6"
        // @ts-ignore
        dataHeaders={size.width > 768 ? ["", "CONSERVADOR", "MODERADO", "AGRESIVO"] : ["", "CONSERV.", "MODER.", "AGRES."]}
        dataRows={[
          ["Retorno promedio anual", "0.13%", "1.17%", "3.00%"],
          ["Standard Deviation", "9.77", "12.64", "15.22"],
          ["Sharpe Ratio", "-0.33", "-0.13", "0.05"]
        ]}
      />
      <SloganRow />
    </>
  )
}

export default SuggestedPortfolios;