import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import * as Constants from "../utils/constants";

const InvestmentTestTabs = (props: { investmentPrompts: any, investmentLevels: any }) => {
  const [key, setKey] = useState("1");
  const [testValues, setTestValues] = useState<number[]>([]);
  const [investmentProfileLevel, setInvestmentProfileLevel] = useState("");

  const handleTestValues = (testValue: number, testNumber: number) => {
    const newTestValues = [...testValues];
    newTestValues[testNumber] = testValue;
    const totalTestValue = newTestValues.reduce((partialSum, a) => partialSum + a, 0);
    setTestValues(newTestValues);
    let assessedInvestmentLevel: string = "";
    for (const investmentLevel of props.investmentLevels) {
      if (totalTestValue >= investmentLevel.min && totalTestValue <= investmentLevel.max) {
        assessedInvestmentLevel = investmentLevel.name;
      }
    }
    setInvestmentProfileLevel(assessedInvestmentLevel);
  }
  const testQuestions: any[] = props.investmentPrompts.map((investmentPrompt: any) => {
    return {
      question: investmentPrompt.fields[0].name,
      options: investmentPrompt.fields[0].radioGroup
    }
  });
  const profileDescriptions: any = {
    conservador: props.investmentPrompts[10].fields[1].defaultValue,
    moderado: props.investmentPrompts[11].fields[1].defaultValue,
    agresivo: props.investmentPrompts[12].fields[1].defaultValue,
  }
  return (
    <Row className="custom-investment-test-row" style={{ padding: "3rem 15rem", textAlign: "center" }}>
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
        className="mb-3"
      >
        {Constants.investorQuizData.map((investorQuizSection: any, i: number) => (
          <Tab
            key={i}
            eventKey={i + 1}
            title={i === Constants.investorQuizData.length - 1 ? "Resultado" : `${i + 1}.`}
            disabled={i === 0 ? false : testValues[i - 1] === undefined ? true : false}
          >
            <Row>
              {i < Constants.investorQuizData.length - 1 ?
                <>
                  <Col xs={12} md={6} className='custom-investment-test-question-col' style={{ fontFamily: "exo2Regular", fontSize: "23px", color: "#323B98", textAlign: "left" }}>
                    {testQuestions[i + 1].question}
                  </Col>
                  <Col xs={12} md={6} className='custom-investment-test-question-col' style={{ textAlign: "left", paddingTop: "1rem" }}>
                    <Form>
                      {testQuestions[i + 1].options?.map((option: any, j: number) => (
                        <React.Fragment key={j}>
                          <div key={`default-radio`} className="mb-3">
                            <Form.Check
                              name="groupOptions"
                              type={"radio"}
                              id={j.toString()}
                              label={option.name}
                              onClick={() => handleTestValues(parseInt(option.value), i)}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                    </Form>
                  </Col>
                </>
                :
                <Col>
                  <Row style={{ fontFamily: "exo2Regular", fontSize: "23px", color: "#323B98", textAlign: "left" }}>
                    {investorQuizSection.question}
                  </Row>
                  <Row style={{ fontFamily: "exo2Semibold", fontSize: "30px", color: "#323B98", textAlign: "left", marginBottom: "1.5rem" }}>
                    {investmentProfileLevel}
                  </Row>
                  <Row style={{ fontFamily: "exo2Medium", fontSize: "16px", textAlign: "left", marginBottom: "2rem" }}>
                    {profileDescriptions[investmentProfileLevel.toLowerCase()]}
                  </Row>
                  <Row>
                    <Col>
                      <Link
                        className="nav-link"
                        to="/contactanos"
                        style={{
                          color: "white",
                          paddingLeft: 0,
                          paddingBottom: "0.8rem",
                          fontFamily: "Exo2Regular"
                        }}
                      >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        >
                          <div
                            id="header-button-alt"
                            className="border d-flex align-items-center justify-content-center"
                            style={{
                              fontFamily: "Exo2Medium", textAlign: "center", fontSize: "17px", height: "3.5rem", padding: "0 1rem 0 1rem", width: "12rem", borderRadius: "10px", lineHeight: "1.4rem"
                            }}
                          >
                            CONTACTANOS
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              }
            </Row>
            {i < Constants.investorQuizData.length - 1 &&
              <Row>
                <Col style={{ textAlign: "end" }}>
                  <Button
                    id="investmentTestBackButton"
                    style={{ fontFamily: "Exo2Medium" }}
                    onClick={() => setKey((i).toString())}
                    disabled={i === 0 ? true : false}
                  >Anterior</Button>
                </Col>
                <Col style={{ textAlign: "initial" }}>
                  <Button
                    id="header-button-alt"
                    style={{ fontFamily: "Exo2Medium" }}
                    onClick={() => setKey((i + 2).toString())}
                    disabled={testValues[i] === undefined ? true : false}
                  >Siguiente</Button>
                </Col>
              </Row>
            }
          </Tab>
        ))}
      </Tabs>
    </Row>
  );
}

export default InvestmentTestTabs;