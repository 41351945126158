import React from "react";
import { PieChart } from 'react-minimal-pie-chart';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const DonutChart = (props: any) => {
  return (
    <>
      <Row style={{ fontFamily: "Exo2SemiBold", fontSize: "26px", marginBottom: "2rem" }}>
        {props.title.toUpperCase()}
      </Row>
      <Row style={{ width: "250px", marginBottom: "2rem" }}>
        <PieChart {...props} />
      </Row>
      <div style={{ minHeight: "8rem" }}>
        {props.data.map((el: any, i: number) => (
          <Row key={i}>
            <Col xs={2} sm={1}>
              <div style={{
                border: `2px solid ${el.color}`,
                width: "28px",
                height: "28px",
                borderRadius: "50%"
              }}></div>
            </Col>
            <Col style={{ textAlign: "left", marginBottom: "1rem" }}>
              {el.title}: {el.value}%
            </Col>
          </Row>
        ))}
      </div>
      <Row className="custom-portafolio-button-row">
        <Button className="pie-chart-button"
          // onClick={() => window.location.href = props.reportLink}
          onClick={() => window.open(props.reportLink)}
        >
          {/* DESCARGAR PORTAFOLIO<br />{props.title.toUpperCase()} */}
          VER PORTAFOLIO
        </Button>
      </Row>
    </>
  )
}

export default DonutChart;