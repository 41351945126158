import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

const DataTable = (props: { title: string, dataHeaders: string[], dataRows: any[][], backgroundColor?: string }) => {
  return (
    <>
      <Row className="custom-charts-row-title" style={{ padding: "1rem 6rem 3rem 6rem", color: "#323B98", backgroundColor: props.backgroundColor ?? "rgba(237, 237, 237, 0.74)", fontFamily: "Exo2Bold", fontSize: "32px", textAlign: "left" }}>
        {props.title}
      </Row>
      <Row className="justify-content-md-center" style={{ backgroundColor: props.backgroundColor ?? "rgba(237, 237, 237, 0.74)", paddingBottom: "3rem" }}>
        <Col xs={12} sm={8} style={{ textAlign: "left" }}>
          <Table hover style={{ backgroundColor: "transparent", border: "white", borderStyle: "none" }}>
            <thead style={{ border: "white" }}>
              <tr style={{ borderStyle: "none", color: "#3B3B3A", fontSize: "20px" }}>
                {props.dataHeaders.map((dataHeader: string, i: number) => (
                  <th style={{ fontFamily: "Exo2SemiBold", backgroundColor: "transparent" }} key={i}>{dataHeader}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.dataRows.map((dataRow: any, i: number) => (
                <tr key={i}>
                  {dataRow.map((dataCell: any, i: number) => (
                    <td className="statValues" key={i}>{dataCell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default DataTable;