import React from "react";
import { Link } from "react-router-dom";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import * as Constants from "../utils/constants";
import SloganRow from "./SloganRow";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';

const Services = () => {
  return (
    <>
      <HeaderWithOptionalImage title="Servicios" bgColor="#EDEDED" paragraph="A través de nuestra ALyC, nos encargamos de la distribución, colocación y negociación de los diferentes instrumentos disponibles en el mercado, brindando un asesoramiento integral que se adapte a la necesidad de cada cliente." paragraphWidth="85%" />
      {/* <div style={{ backgroundColor: "#EDEDED", height: "16rem", width: "100%", position: "absolute", zIndex: -100 }}></div> */}
      <Row className="justify-content-md-center custom-services-row" style={{ padding: "0 10rem 0 10rem", marginTop: "-3rem" }}>
        {Constants.servicesData.map((datum: any, i: number) => (
          <Col key={i} xs={12} md={3} style={{ fontFamily: "exo2Regular", fontSize: "14px", color: "#3B3B3A" }}>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              height: "8rem"
            }}>
              <Link
                className="nav-link"
                to={datum.path}
                style={{
                  color: "white",
                  paddingLeft: 0,
                  paddingBottom: "0.8rem",
                  fontFamily: "Exo2Regular"
                }}
              >
                <Image fluid src={datum.image} alt={datum.image} style={{
                  flexShrink: 0,
                  width: "7rem"
                }} />
              </Link>
            </div>
            <Link
              className="nav-link"
              to={datum.path}
              style={{
                color: "white",
                paddingLeft: 0,
                paddingBottom: "0.8rem",
                fontFamily: "Exo2Regular"
              }}
            >
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              >
                <div
                  id="header-button-alt"
                  className="border d-flex align-items-center justify-content-center"
                  style={{
                    fontFamily: "Exo2Bold", textAlign: "center", fontSize: "17px", height: "3.5rem", padding: "0 1rem 0 1rem", width: "12rem", borderRadius: "15px", lineHeight: "1.4rem"
                  }}
                // onClick={() => window.location.href = window.location.pathname + datum.path}
                >
                  {datum.cta}
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
      <SloganRow />
    </>
  )
}

export default Services;