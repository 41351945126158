import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';
import * as Constants from "../utils/constants";
import HeaderWithOptionalImage from "./HeaderWithOptionalImage";
import SloganRow from "./SloganRow";

const SingleCard = (props: { title: string, paragraph: string, icon: any }) => {
  return (
    <Col className="custom-products-col" xs={12} sm={4} xl={2} style={{ margin: "0 0.5rem 1rem 0.5rem", zIndex: 10 }}>
      <Row className="mx-auto" style={{ width: "150px", color: "white", padding: "0.5rem", marginBottom: "2rem" }}>
        <Col className="m-auto">
          <Image className="d-block mx-auto img-fluid w-100" fluid src={props.icon} alt={props.icon} />
        </Col>
      </Row>
      <Row style={{ minHeight: "360px", backgroundColor: "white", boxShadow: "2px 3px 2px #00000029" }}>
        <Col>
          <Row style={{ backgroundColor: "#E6E6E6" }}>
            <div style={{ fontFamily: "utopiaSemibold", fontSize: "24px", height: "6rem", lineHeight: "2rem", verticalAlign: "middle", textAlign: "left", padding: "1rem 2rem 0.5rem 2rem", color: "#323B98", backgroundColor: "#E6E6E6" }}>
              {props.title}
            </div>
          </Row>
          <Row>
            <div>
              <div style={{ fontFamily: "Exo2Regular", fontSize: "18px", padding: "1rem", textAlign: "left", lineHeight: "1.4rem", minHeight: "15rem" }}>
                {props.paragraph}
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </Col >
  )
}

const Asesoramiento = () => {
  return (
    <>
      <HeaderWithOptionalImage
        title="Asesoramiento"
        paragraph="En Winvest elaboramos propuestas personalizadas en función de los objetivos y necesidades de cada cliente."
        bgColor="#EDEDED"
        bgImage={Constants.asesoramientoBg}
        opacityLayerColor="transparent"
      />
      <Row className="justify-content-center" style={{ padding: "3rem 2rem", textAlign: "left" }}>
        <SingleCard
          title="Planificación"
          paragraph="Identificamos las necesidades del cliente determinando los objetivos e instrumentos de inversión, dado su horizonte de tiempo y perfil."
          icon={Constants.iconCalendar}
        />
        <SingleCard
          title="Análisis de Mercado"
          paragraph="Detectamos oportunidades de mercado a través del análisis económico y proyección de rendimientos."
          icon={Constants.iconPointChart}
        />
        <SingleCard
          title="Gestión de Portafolio"
          paragraph="Mediante una ejecución oportuna y eficiente, brindamos el servicio de administración de carteras."
          icon={Constants.iconPlus}
        />
        <SingleCard
          title="Monitoreo"
          paragraph="A través de un control periódico, las carteras son revisadas regularmente presentando sugerencias de rebalanceo al cliente."
          icon={Constants.iconChart}
        />
      </Row>
      <SloganRow />
    </>
  )
}

export default Asesoramiento;