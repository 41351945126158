import React from "react";
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import RouterBreadcrumbs from "./RouterBreadcrumbs";

const HeaderWithOptionalImage = (props: { title: string, bgColor: string, paragraph?: string, paragraphWidth?: string, bgImage?: any, titleFontSize?: string, paragraphFontSize?: string, opacityLayerColor?: string }) => {
  const parsedParagraph: string[] | undefined = props.paragraph?.split("//");
  if (props.bgImage) {
    return (
      <>
        <Row>
          <Image id="image-for-page-header" src={props.bgImage} alt={props.bgImage} style={{ height: "540px", objectFit: "cover" }} />
          <div id="over" style={{ backgroundColor: props.opacityLayerColor ?? "rgba(0, 0, 0, 0.38)" }}>
            <div id="page-header-text-container" style={{ marginTop: "300px" }}>
              <RouterBreadcrumbs bgColor={"transparent"} paddingTop="" />
              <div style={{ color: "#323B98" }}>
                <div style={{
                  fontFamily: "Exo2Semibold",
                  fontSize: props.titleFontSize ?? "36px",
                  paddingTop: "100px"
                }}>
                  {props.title}
                </div>
                {props.paragraph && (
                  <div className="header-paragraph" style={{ margin: "2rem auto", fontFamily: "Exo2Semibold", fontSize: props.paragraphFontSize ?? "22px", width: props.paragraphWidth ?? "50%", display: "block", paddingBottom: "2rem" }}>
                    {parsedParagraph?.map((paragr, i: number) => (
                      <p key={i}>{paragr}</p>
                    ))}
                  </div>
                )}
              </div>
              <div style={{ fontFamily: "Exo2Regular", fontSize: "22px", width: props.paragraphWidth ?? "50%", display: "block", margin: "auto", paddingBottom: "2rem" }}>
                {/* <Image src={Constants.bannerArrow} alt={Constants.bannerArrow} style={{ height: "20px", marginTop: "80px", marginBottom: "80px" }} /> */}
              </div>
            </div>
          </div>
        </Row>
      </>
    )
  } else {
    return (
      <>
        <RouterBreadcrumbs bgColor={props.bgColor} paddingTop="38px" />
        <Row className="custom-row custom-header-without-image-row" style={{ textAlign: "center", padding: "4rem 6rem 0 6rem", color: "#323B98", backgroundColor: props.bgColor, minHeight: "160px" }}>
          <div style={{ fontFamily: "Exo2Semibold", padding: "0 0 2rem 0", fontSize: props.titleFontSize ?? "36px" }}>
            {props.title}
          </div>
          {props.paragraph && (
            <div className="header-paragraph" style={{ fontFamily: "Exo2Semibold", fontSize: props.paragraphFontSize ?? "22px", width: props.paragraphWidth ?? "50%", display: "block", margin: "0 auto", paddingBottom: "5rem" }}>
              {parsedParagraph?.map((paragr, i: number) => (
                <p key={i}>{paragr}</p>
              ))}
            </div>
          )}
        </Row>
      </>
    )
  }
}

export default HeaderWithOptionalImage;