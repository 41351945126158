import React from "react";
import BootstrapCarousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const RowWithBgImage = (props: { title: string, titleColor: string, bgImage: any, smallImages: any, deviceType?: any, controls: boolean }) => {
  return (
    <Row className="alianzas-custom-row" style={{ padding: "4rem 6rem", backgroundImage: `url(${props.bgImage})`, height: "549px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
      <Col className="alianzas-custom-col mx-auto"
        sm={12} md={6}
        style={{ fontFamily: "exo2SemiBold", fontSize: "50px", color: props.titleColor, textAlign: "left", display: "flex", alignItems: "center", lineHeight: "3rem" }}>
        {props.title.split(" ").map((titleWord: string, i: number) => (
          <React.Fragment key={i}>
            {titleWord}<br />
          </React.Fragment>
        ))}
      </Col>
      <Col className="custom-images-col" sm={12} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Row className="mx-auto" style={{ margin: "0.75rem" }}>
          <Row>
            <BootstrapCarousel
              interval={2000}
              controls={props.controls}
              indicators={false}
              touch={true}
            >
              <BootstrapCarousel.Item>
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                        <a href={props.smallImages[0][0].url} target="_blank" rel="noopener noreferrer">
                          <Image fluid src={props.smallImages[0][0].img} alt={props.smallImages[0][0].img} />
                        </a>
                      </Col>
                      <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                        <a href={props.smallImages[0][1].url} target="_blank" rel="noopener noreferrer">
                          <Image fluid src={props.smallImages[0][1].img} alt={props.smallImages[0][1].img} />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} >
                    <Row>
                      <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                        <a href={props.smallImages[1][0].url} target="_blank" rel="noopener noreferrer">
                          <Image fluid src={props.smallImages[1][0].img} alt={props.smallImages[1][0].img} />
                        </a>
                      </Col>
                      <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                        <a href={props.smallImages[1][1].url} target="_blank" rel="noopener noreferrer">
                          <Image fluid src={props.smallImages[1][1].img} alt={props.smallImages[1][1].img} />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} >
                    <Row>
                      <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                        <a href={props.smallImages[2][0].url} target="_blank" rel="noopener noreferrer">
                          <Image fluid src={props.smallImages[2][0].img} alt={props.smallImages[2][0].img} />
                        </a>
                      </Col>
                      <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                        <a href={props.smallImages[2][1].url} target="_blank" rel="noopener noreferrer">
                          <Image fluid src={props.smallImages[2][1].img} alt={props.smallImages[2][1].img} />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </BootstrapCarousel.Item>
              {props.smallImages[3] &&
                <BootstrapCarousel.Item>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                          <a href={props.smallImages[3][0].url} target="_blank" rel="noopener noreferrer">
                            <Image fluid src={props.smallImages[3][0].img} alt={props.smallImages[3][0].img} />
                          </a>
                        </Col>
                        {props.smallImages[3][1] &&
                          <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                            <a href={props.smallImages[3][1].url} target="_blank" rel="noopener noreferrer">
                              <Image fluid src={props.smallImages[3][1].img} alt={props.smallImages[3][1].img} />
                            </a>
                          </Col>
                        }
                      </Row>
                    </Col>
                    {props.smallImages[4] ?
                      <Col xs={12} >
                        <Row>
                          <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                            <a href={props.smallImages[4][0].url} target="_blank" rel="noopener noreferrer">
                              <Image fluid src={props.smallImages[4][0].img} alt={props.smallImages[4][0].img} />
                            </a>
                          </Col>
                          <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                            <a href={props.smallImages[4][1].url} target="_blank" rel="noopener noreferrer">
                              <Image fluid src={props.smallImages[4][1].img} alt={props.smallImages[4][1].img} />
                            </a>
                          </Col>
                        </Row>
                      </Col>
                      :
                      <Row>
                        <Col xs={5} style={{ padding: 0, margin: "0.75rem", width: "271px", height: "109px" }}>
                        </Col>
                        <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                        </Col>
                      </Row>
                    }
                    <Col xs={12} >
                      {/* Workaround to keep row height even if last element (5) is not sent */}
                      {props.smallImages[5] ?
                        <Row>
                          <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                            <a href={props.smallImages[5][0].url} target="_blank" rel="noopener noreferrer">
                              <Image fluid src={props.smallImages[5][0].img} alt={props.smallImages[5][0].img} />
                            </a>
                          </Col>
                          <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                            <a href={props.smallImages[5][1].url} target="_blank" rel="noopener noreferrer">
                              <Image fluid src={props.smallImages[5][1].img} alt={props.smallImages[5][1].img} />
                            </a>
                          </Col>
                        </Row>
                        :
                        <Row>
                          <Col xs={5} style={{ padding: 0, margin: "0.75rem", width: "271px", height: "109px" }}>
                          </Col>
                          <Col xs={5} style={{ padding: 0, margin: "0.75rem" }}>
                          </Col>
                        </Row>
                      }
                    </Col>
                  </Row>
                </BootstrapCarousel.Item>}
            </BootstrapCarousel>
          </Row>
          {/* } */}
        </Row >
      </Col >
    </Row >
  )
}

export default RowWithBgImage;